import * as React from "react"
import { graphql } from 'gatsby'
import { Container, Grid, GridItem, Image, Link, useColorMode } from "@chakra-ui/react"
import _ from 'lodash';
import { Link as GatsbyLink } from "gatsby"

export function Head() {
  return (<>
    <html lang="en" />
    <meta httpEquiv="content-language" content="en" />
    <title>JJR | Collection</title>
    <meta property="og:title" content="JJR | Collection" />
    <meta property="og:description" content="Jonathan Joseph Rosse Jewelry" />
    <meta property="og:image" content="/images/og_image.jpg" />
  </>)
}

export const data = graphql`
query CollectionPageContentful {
  allContentfulType(sort: {order: ASC, fields: order}) {
    nodes {
      piece {
        darkImages {
          resize(width: 520, quality: 100) {
            src
          }
        }
        lightImages {
          resize(width: 520, quality: 100) {
            src
          }
        }
        featured
        featuredEnd
        slug
        order
      }
    }
  }
}
`;

const CollectionPage = ({ data }) => {
  const { colorMode } = useColorMode()

  const imagesWithSlugs = React.useMemo(() => {
    const allImagesWithSlugs = data.allContentfulType.nodes.flatMap(node =>
      node.piece.flatMap(piece => 
        (colorMode === "light" ? piece.lightImages : piece.darkImages).map(img => ({
          src: img.resize.src,
          slug: piece.slug,
          order: piece.order
        }))
      )
    );
    return allImagesWithSlugs.sort((a, b) => a.order - b.order);
  }, [data, colorMode]);

  return (
    <Container maxW='container.xl' py={6}>
      <Grid 
        templateColumns={['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} 
        gap={6} 
        alignItems="center" 
        mb="8"
      >
        {imagesWithSlugs.map((image, index) => (
          <GridItem key={index} position="relative" paddingBottom="100%">
            <Link  as={GatsbyLink} position="absolute" top="0" left="0" right="0" bottom="0" to={`/collection/${image.slug}`} _hover={{opacity: 0.75}}>
              <Image 
                src={image.src} 
                objectFit="contain" 
                w="100%" 
                h="100%"
              />
            </Link>
          </GridItem>
        ))}
      </Grid>
    </Container>
  )
}

export default CollectionPage